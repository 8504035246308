import type { RxJsonSchema } from 'rxdb';

type ApplicationSettings = {
	settingsId: string;
	ccfApplicationSettings: string;
	testReasons: string;
	hairLocationLookupValues: string;
	anonymousTestLookupValues: string;
	languageLookupValues: string;
	yesNoLookupValues: string;
	testSubprocessResultLookupValues: string;
	donorSexLookupValues: string;
	hairTypeLookupValues: string;
	sampleTypes: string;
	overallResultTypes: string;
};

export const applicationSettings: RxJsonSchema<ApplicationSettings> = {
	title: 'applicationsettings',
	version: 3,
	primaryKey: 'settingsId',
	type: 'object',
	properties: {
		settingsId: {
			type: 'string',
			maxLength: 5
		},
		ccfApplicationSettings: {
			type: 'string'
		},
		testReasons: {
			type: 'string'
		},
		hairLocationLookupValues: {
			type: 'string'
		},
		anonymousTestLookupValues: {
			type: 'string'
		},
		languageLookupValues: {
			type: 'string'
		},
		yesNoLookupValues: {
			type: 'string'
		},
		testSubprocessResultLookupValues: {
			type: 'string'
		},
		donorSexLookupValues: {
			type: 'string'
		},
		hairTypeLookupValues: {
			type: 'string'
		},
		sampleTypes: {
			type: 'string'
		},
		overallResultTypes: {
			type: 'string'
		}
	}
};
