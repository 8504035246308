import type { IconProps } from './types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleRight,
	faAngleLeft,
	faBars,
	faRotate,
	faSlash,
	faSortDown,
	faSortUp,
	faTrash,
	faSquareCheck,
	faSquareXmark
} from '@fortawesome/free-solid-svg-icons';

const IconMap : { [ key: string ]: Function } = {
	trash: Trash,
	burger: Burger,
	arrowDown: ArrowDown,
	arrowUp: ArrowUp,
	arrowRight: ArrowRight,
	arrowLeft: ArrowLeft,
	activeSpinner: ActiveSpinner,
	inactiveSpinner: InactiveSpinner,
	spinner: Spinner,
	squareCheck: SquareCheck,
	squareX: SquareX
};

function SquareX() {
	return <FontAwesomeIcon icon={ faSquareXmark } color="#dddddd" />;
}

function SquareCheck({ color = '#676767' }: { color?: string }) {
	return <FontAwesomeIcon icon={ faSquareCheck } color={ color } />;
}

function Trash() {
	return <FontAwesomeIcon icon={ faTrash } color="#676767" />;
}

function Burger() {
	return <FontAwesomeIcon icon={ faBars } color="#387ef5" size="1x" />;
}

function ArrowDown() {
	return <FontAwesomeIcon icon={ faSortDown } color="#444" size="1x" />;
}

function ArrowUp() {
	return <FontAwesomeIcon icon={ faSortUp } color="#444" size="1x" />;
}

function ArrowRight() {
	return <FontAwesomeIcon icon={ faAngleRight } color="#444" />;
}

function ArrowLeft() {
	return <FontAwesomeIcon icon={ faAngleLeft } color="#444" />;
}

function Spinner() {
	return <FontAwesomeIcon icon={ faRotate } color="#777" />;
}

function InactiveSpinner() {
	return (
		<span>
			<span className="absolute top-0 left-0.5">
				<FontAwesomeIcon icon={ faRotate } color="#333" />
			</span>

			<span className="absolute top-0 left-0">
				<FontAwesomeIcon icon={ faSlash } color="#b22e2e" />
			</span>
		</span>
	);
}

function ActiveSpinner() {
	return (
		<span>
			<span className="absolute top-0 left-0.5">
				<FontAwesomeIcon icon={ faRotate } color="#333" />
			</span>
		</span>
	);
}

export default function Icon({ glyph, color, className } : IconProps ) {
	const DisplayedIcon = IconMap[ glyph ];

	return (
		<div className={ `inline-block max-w-[24px] ${ className }` }>
			<DisplayedIcon color={ color } />
		</div>
	);
}
