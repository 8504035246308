import Modal from 'react-modal';
import Loading from 'react-loading';

import { useLoading } from '@/hooks/useLoading';

Modal.setAppElement( '#__next' );

const styles = {
	content: {
		width: '150px',
		height: '150px',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		margin: 'auto'
	}
};

export default function LoadingModal() {
	const { modalText, isModalOpen } = useLoading();

	return (
		<Modal
			isOpen={ isModalOpen }
			style={ styles }
			contentLabel="Loading..."
		>
			<div className="flex flex-col justify-center items-center h-full">
				<Loading type="spin" color="#676767" width={ 32 } height={ 32 } />

				<span className="mt-3 text-gray-500 text-sm italic text-center">{ modalText }</span>
			</div>
		</Modal>
	);
}
