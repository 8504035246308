import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

type DateInput = Date | string | null;
type DateString = string | null;

dayjs.extend( customParseFormat );
dayjs.extend( utc );

const defaultDateFormats: Record<string, string> = {
	input: 'YYYY-MM-DDTHH:mm:ss[Z]',
	outputDate: 'DD/MM/YYYY',
	outputDatetime: 'DD/MM/YYYY HH:mm',
	outputDateString: 'DD/MM/YYYY HH:mm:ss',
	outputTime: 'HH:mm'
};

export function unserializeDateField( dateString: DateString, dateFormat = defaultDateFormats.input ) {
	return dateString ? dayjs( dateString, dateFormat ).toDate() : '';
}

export function serializeDateField( dateObj: Date, formatString: string = defaultDateFormats.input ) {
	return dateObj ? dayjs( dateObj ).format( formatString ) : '';
}

export function formatDate( dateObj: DateInput ) {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputDate ) : '';
}

export function formatDatetime( dateObj: DateInput ) {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputDatetime ) : '';
}

export function formatDateString( dateString: DateString ) {
	return dateString ? dayjs( dateString ).format( defaultDateFormats.outputDate ) : '';
}

export function formatTime( dateObj: DateInput ) {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputTime ) : '';
}

export function getCurrentTimestamp() {
	return dayjs().utc().unix();
}

export function getDateTimestamp( dateString: string ) {
	return dayjs( dateString, defaultDateFormats.input ).utc().unix();
}

export function getCurrentDateString( dateFormat: string = defaultDateFormats.outputDateString ) {
	return dayjs().format( dateFormat );
}

export function getCurrentYear() {
	return dayjs().year();
}
