import type { RxJsonSchema } from 'rxdb';

type CredentialsStore = {
	password: string;
	username: string;
	collectionSiteId: string;
	jwt: string;
	rt: string;
	iterations: number;
};

export const tempCredentials: RxJsonSchema<CredentialsStore> = {
	title: 'tmpstorecreds',
	version: 1,
	primaryKey: 'username',
	type: 'object',
	properties: {
		username: {
			type: 'string',
			maxLength: 50
		},
		password: {
			type: 'string'
		},
		collectionSiteId: {
			type: 'string'
		},
		jwt: {
			type: 'string'
		},
		rt: {
			type: 'string'
		},
		iterations: {
			type: 'number'
		}
	}
};
