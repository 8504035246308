import type { RxJsonSchema } from 'rxdb';

type RemoteCollection = {
	collectionId: string;
	collectionObject: string;
	collectionSiteId: string;
	isSynced: boolean;
	collectionDate: number,
	updatedAt: number;
	status: string;
};

export const remotecollections: RxJsonSchema<RemoteCollection> = {
	title: 'remotecollections',
	version: 7,
	primaryKey: 'collectionId',
	type: 'object',
	properties: {
		collectionId: {
			type: 'string',
			maxLength: 30
		},
		collectionObject: {
			type: 'string'
		},
		collectionSiteId: {
			type: 'string'
		},
		isSynced: {
			type: 'boolean'
		},
		collectionDate: {
			type: 'datetime'
		},
		updatedAt: {
			type: 'datetime'
		},
		status: {
			type: 'string'
		}
	}
};
