import type { RxJsonSchema } from 'rxdb';

type Translations = {
	key: string;
	value: string;
};

export const translations: RxJsonSchema<Translations> = {
	title: 'translations',
	version: 1,
	primaryKey: 'key',
	type: 'object',
	properties: {
		key: {
			type: 'string',
			maxLength: 5
		},
		value: {
			type: 'string'
		}
	}
};
