import type { ReactElement } from 'react';

import { Provider as CollectionsProvider } from 'rxdb-hooks';

import ConfirmModal from '@/components/ConfirmModal';
import LoadingModal from '@/components/LoadingModal';
import PageTitle from '@/components/PageTitle';
import SentryManager from '@/components/SentryManager';

import { ConfirmProvider } from '@/hooks/useConfirm';

const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN || '';

export default function Translated({
	collectionsDb,
	Component,
	pageProps,
	session
}: any ) {
	const getLayout = Component.getLayout ?? ( ( page: ReactElement ) => page );

	return (
		<CollectionsProvider db={ collectionsDb }>
			<ConfirmProvider>
				<PageTitle />
				
				{ getLayout( <Component { ...pageProps } /> ) }

				<LoadingModal />
				<ConfirmModal />

				{ sentryDsn && <SentryManager session={ session } /> }
			</ConfirmProvider>
		</CollectionsProvider>
	);
}
