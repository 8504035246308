import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';

import { translations } from './schemas/translations';

addRxPlugin( RxDBMigrationPlugin );

let translationsDb: RxDatabase;

async function createTranslationsDatabase() {
	translationsDb = await createRxDatabase({
		name: 'occf_translations',
		storage: getRxStorageDexie(),
		multiInstance: true
	});
}

export async function initializeTranslations() {
	if ( !translationsDb ) {
		await createTranslationsDatabase();
	}

	if ( !translationsDb.translations ) {
		await translationsDb.addCollections({
			translations: {
				schema: translations
			}
		});
	}

	return translationsDb;
}
