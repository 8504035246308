import { defineMessages } from 'react-intl';

export const sharedText = defineMessages({
	siteTitle: {
		id: 'Shared_SiteName',
		defaultMessage: 'Online Chain of Custody System'
	},
	login: {
		id: 'Login_LoginBtn',
		defaultMessage: 'Log In'
	},
	confirmDelete: {
		id: 'UNKNOWN',
		defaultMessage: 'Are you sure you want to delete this collection?'
	},
	cannotBeUndone: {
		id: 'UNKNOWN',
		defaultMessage: 'This action cannot be undone.'
	},
	yesDelete: {
		id: 'UNKNOWN',
		defaultMessage: 'Yes, delete'
	},
	noCancel: {
		id: 'UNKNOWN',
		defaultMessage: 'No, cancel'
	},
	syncing: {
		id: 'UNKNOWN',
		defaultMessage: 'Syncing...'
	},
	lastUpdated: {
		id: 'UNKNOWN',
		defaultMessage: 'Last updated:'
	}
});
