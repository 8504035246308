type ErrorObject = {
	code: string;
	translationKey: string;
	message: string;
};

export const saveErrors: Record<number, ErrorObject> = {
	1: {
		code: 'SaveSuccessful',
		translationKey: 'UNKNOWN',
		message: 'Collection saved successfully'
	},
	2: {
		code: 'SpecimenIdInUse',
		translationKey: '',
		message: 'Specimen ID in use'
	},
	3: {
		code: 'AuthenticationFailed',
		translationKey: '',
		message: 'Authentication failed'
	},
	4: {
		code: 'SpecimenIdTooLong',
		translationKey: '',
		message: 'Specimen ID is too long'
	},
	5: {
		code: 'NotesTooLong',
		translationKey: '',
		message: 'Collection notes are too long'
	},
	6: {
		code: 'SpecimenIdEmpty',
		translationKey: '',
		message: 'Specimen ID not provided'
	},
	7: {
		code: 'ValidationPassed',
		translationKey: '',
		message: 'Validation passed'
	},
	8: {
		code: 'TestRequestAlreadyCancelled',
		translationKey: '',
		message: 'Test Request already cancelled'
	},
	9: {
		code: 'TestRequestAlreadyCompleted',
		translationKey: '',
		message: 'Test Request already completed'
	},
	10: {
		code: 'LaboratoryNotSet',
		translationKey: '',
		message: 'Laboratory not set'
	},
	11: {
		code: 'PocOverallResultNotSet',
		translationKey: '',
		message: 'Poc Overall Result not set'
	},
	12: {
		code: 'PocOverallResultMustBePositiveCheckFailed',
		translationKey: '',
		message: 'Poc Overall Result must be positive'
	},
	13: {
		code: 'SubprocessesMustHaveResults',
		translationKey: '',
		message: 'Subprocesses must have results'
	},
	14: {
		code: 'CollectionDateIsRequired',
		translationKey: '',
		message: 'Collection date not provided'
	},
	15: {
		code: 'DonorDeclarationRequired',
		translationKey: '',
		message: 'Donor declaration is required'
	},
	16: {
		code: 'TestReasonIsEmpty',
		translationKey: '',
		message: 'Test reason not provided'
	},
	17: {
		code: 'RequestingAgencyIsEmpty',
		translationKey: '',
		message: 'Requesting agency is not provided'
	},
	18: {
		code: 'CollectionSiteIsEmpty',
		translationKey: '',
		message: 'Collection site is not provided'
	},
	19: {
		code: 'TestTypeIsEmpty',
		translationKey: '',
		message: 'Test type is not provided'
	}
};
