import Modal from 'react-modal';
import { useIntl } from 'react-intl';

import Button from '@/components/Button';
import Header from '@/components/Header';

import { useConfirm } from '@/hooks/useConfirm';
import { sharedText } from '@/translations/shared';

Modal.setAppElement( '#__next' );

const customStyles = {
	content: {
		zIndex: '3001',
		maxWidth: '400px',
		top: '50%',
		right: '0',
		bottom: 'auto',
		left: '0',
		margin: 'auto',
		padding: '30px',
		transform: 'translateY(-50%)',
	},
	overlay: {
		zIndex: '3000',
		backgroundColor: 'rgba(255, 255, 255, 0.9)'
	}
}

export default function ConfirmModal() {
	const intl = useIntl();

	const {
		isOpen = false,
		proceed,
		cancel
	} = useConfirm();

	return (
		<Modal
			isOpen={ isOpen }
			onRequestClose={ cancel }
			style={ customStyles }
		>
			<div>
				<Header element="h2" variant="h5">{ intl.formatMessage( sharedText.confirmDelete ) }</Header>

				<p className="mt-2 mb-5 text-sm">{ intl.formatMessage( sharedText.cannotBeUndone ) }</p>

				<div className="flex gap-x-4">
					<Button variant="primary" onClick={ proceed }>{ intl.formatMessage( sharedText.yesDelete ) }</Button>
					<Button variant="text" onClick={ cancel }>{ intl.formatMessage( sharedText.noCancel ) }</Button>
				</div>
			</div>
		</Modal>
	);
}
