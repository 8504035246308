import type { RxJsonSchema } from 'rxdb';

type CollectionSiteSettings = {
	collectionSiteId: string;
	collectionSite: string;
	requestingAgencies: string;
	testSubprocesses: string;
	testSubstanceResults: string;
	testTemplates: string;
};

export const collectionSiteSettings: RxJsonSchema<CollectionSiteSettings> = {
	title: 'collectionsitesettings',
	version: 3,
	primaryKey: 'collectionSiteId',
	type: 'object',
	properties: {
		collectionSiteId: {
			type: 'string',
			maxLength: 30
		},
		collectionSite: {
			type: 'string'
		},
		requestingAgencies: {
			type: 'string'
		},
		testSubprocesses: {
			type: 'string'
		},
		testSubstanceResults: {
			type: 'string'
		},
		testTemplates: {
			type: 'string'
		}
	}
};
