import type { Collection } from '@/types/collection';

export type SavingParams = {
	checkCredentials?: boolean;
	finishCollection?: boolean;
	savingDraft?: boolean;
	skipTestResultValidation?: boolean;
	userPassword?: null | string;
	isDonorWithdrawClicked?: boolean;
};

export type Route = {
	key: string;
	urlHash: string;
	componentKey: string;
	savingParams?: SavingParams;
};

export function getSavingParams( stepName?: string, context?: Collection ): SavingParams {
	let returnParams: SavingParams = {};

	const defaultParams: SavingParams = {
		checkCredentials: false,
		finishCollection: false,
		savingDraft: true,
		skipTestResultValidation: true,
		userPassword: null
	};

	if ( !stepName ) {
		returnParams = { ...defaultParams };
	} else {
		const matchedRoute = routes[ stepName ];

		if ( matchedRoute?.savingParams ) {
			returnParams = { ...defaultParams, ...matchedRoute.savingParams };
		}
	}

	if ( returnParams.finishCollection || returnParams.checkCredentials ) {
		returnParams = {
			...returnParams,
			userPassword: context?.meta.collectorPwd
		};
	}

	return returnParams;
}

export const routes: Record<string, Route> = {
	controlForm: {
		key: 'controlForm',
		urlHash: 'control-form',
		componentKey: 'ControlForm',
		savingParams: {
			// Not sure if we ever need to check password since that happens as the data
			// is provided in the form.
			// checkCredentials: true,
			savingDraft: false,
			// userPassword: null
		}
	},
	donorInfo: {
		key: 'donorInfo',
		urlHash: 'donor-info',
		componentKey: 'DonorInfo'
	},
	donorSignature: {
		key: 'donorSignature',
		urlHash: 'donor-signature',
		componentKey: 'DonorSignature'
	},
	finalReview: {
		key: 'finalReview',
		urlHash: 'final-review',
		componentKey: 'FinalReview',
		savingParams: {
			// checkCredentials: true,
			finishCollection: true,
			savingDraft: false,
			skipTestResultValidation: false,
			// userPassword: null
		}
	},
	mroInfo: {
		key: 'mroInfo',
		urlHash: 'mro-info',
		componentKey: 'MroInfo'
	},
	pocResults: {
		key: 'pocResults',
		urlHash: 'poc-results',
		componentKey: 'PocResults',
		savingParams: {
			skipTestResultValidation: false
		}
	},
	printSummary: {
		key: 'printSummary',
		urlHash: 'print-summary',
		componentKey: 'PrintSummary'
	},
	requestingAgency: {
		key: 'requestingAgency',
		urlHash: 'requesting-agency',
		componentKey: 'RequestingAgency'
	},
	sampleTypes: {
		key: 'sampleTypes',
		urlHash: 'sample-types',
		componentKey: 'SampleTypes'
	},
	specimenResults: {
		key: 'specimenResults',
		urlHash: 'specimen-results',
		componentKey: 'SpecimenResults',
		savingParams: {
			savingDraft: false
		}
	},
	withdrawalComplete: {
		key: 'withdrawalComplete',
		urlHash: 'withdrawal-complete',
		componentKey: 'WithdrawalComplete'
	},
	ccfSummary: {
		key: 'ccfSummary',
		urlHash: 'ccf-summary',
		componentKey: 'CcfSummary'
	}
};

export function getRouteByUrl( url?: string ): undefined | any {
	if ( !url ) {
		return undefined;
	}

	const matchedEntry = Object.entries( routes ).find( ( [ , val ] ) => {
		return val.urlHash === url;
	});

	if ( matchedEntry ) {
		return matchedEntry[ 1 ];
	} else {
		return undefined;
	}
}

export function routeArray(): Route[] {
	return Object.entries( routes ).map( ( [ key, val ] ) => ( val ) );
}
