import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type ProviderProps = {
	children: ReactNode;
};

type ReturnValue = {
	openModal: Function;
	closeModal: Function;
	modalText: string;
	isModalOpen: boolean;
};

const LoadingContext = createContext<ReturnValue | undefined>( undefined );

function LoadingProvider({ children } : ProviderProps ) {
	const [ isModalOpen, setModalOpen ] = useState( false );
	const [ modalText, setModalText ] = useState( '' );

	const defaultText = 'Loading...';

	const openModal = useCallback( ( text: string = defaultText ) => {
		setModalOpen( true );
		setModalText( text );
	}, [ setModalOpen, setModalText ] );

	const closeModal = useCallback( () => {
		setModalOpen( false );
		setModalText( defaultText );
	}, [ defaultText, setModalOpen, setModalText ] );

	const value: ReturnValue = useMemo( () => ({
		openModal,
		closeModal,
		modalText,
		isModalOpen
	}), [ closeModal, isModalOpen, modalText, openModal ] );

	return <LoadingContext.Provider value={ value }>{ children }</LoadingContext.Provider>;
}

function useLoading() {
	const context = useContext( LoadingContext );

	if ( context === undefined ) {
		throw new Error( 'useLoading must be used within a LoadingProvider' );
	}

	return context;
}

export { LoadingProvider, useLoading };
