import type { RxJsonSchema } from 'rxdb';

type LocalCollection = {
	collectionId: string;
	collectionObject: string;
	isStaged: boolean;
	isSynced: boolean;
	syncedAt: number;
	createdAt: number;
	createdBy: string;
	updatedAt: number;
};

export const localcollections: RxJsonSchema<LocalCollection> = {
	title: 'localcollections',
	version: 7,
	primaryKey: 'collectionId',
	type: 'object',
	properties: {
		collectionId: {
			type: 'string',
			maxLength: 30
		},
		collectionObject: {
			type: 'string'
		},
		isStaged: {
			type: 'boolean'
		},
		isSynced: {
			type: 'boolean'
		},
		syncedAt: {
			type: 'datetime'
		},
		createdAt: {
			type: 'datetime'
		},
		createdBy: {
			type: 'string'
		},
		updatedAt: {
			type: 'datetime'
		}
	}
};
