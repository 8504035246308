import { assign, createMachine, interpret } from 'xstate';

import { getCurrentDateString } from '@/services/date';
import { syncRecords } from '@/stores/db/initializeCollections';
import { setStorageItem } from '@/stores/localstorage';

interface SyncContext {
	lastUpdated: string;
	isSyncing: boolean;
	isError: boolean;
	errorMessage: string;
}

type SyncEvents =
	| { type: 'SYNC_DOCUMENTS' }
	| { type: 'ERROR', message: string };

type SyncTypestate =
	| { value: 'idle', context: SyncContext }
	| { value: 'syncing', context: SyncContext };

export const syncMachine = createMachine<SyncContext, SyncEvents, SyncTypestate>({
	id: 'ccfApp',
	initial: 'idle',
	context: {
		lastUpdated: '',
		isSyncing: false,
		isError: false,
		errorMessage: ''
	},
	on: {
		ERROR: {
			actions: [ 'setError', 'clearSyncing' ],
			target: '#idle'
		}
	},
	states: {
		idle: {
			id: 'idle',
			on: {
				SYNC_DOCUMENTS: {
					actions: [ 'setSyncing' ],
					target: 'syncing'
				}
			}
		},
		syncing: {
			entry: [ 'clearError' ],
			invoke: {
				id: 'syncing',
				src: () => syncRecords(),
				onDone: {
					target: 'idle',
					actions: [
						'clearSyncing',
						'updateTimestamp',
						() => {
							console.log( 'documents have been synced' );
						}
					]
				},
				onError: {
					target: 'idle',
					actions: [
						'clearSyncing',
						'updateTimestamp',
						() => {
							console.log( 'error syncing documents' );
						}
					]
				}
			}
		}
	}
}, {
	actions: {
		setError: assign(( context: SyncContext, event: any ) => {
			return {
				...context,
				isError: true,
				errorMessage: event.message
			};
		}),
		clearError: assign(( context: SyncContext, event: any ) => {
			return {
				...context,
				isError: false,
				errorMessage: ''
			};
		}),
		setSyncing: assign(( context: SyncContext, event: any ) => {
			return {
				...context,
				isSyncing: true
			};
		}),
		clearSyncing: assign(( context: SyncContext, event: any ) => {
			return {
				...context,
				lastUpdated: getCurrentDateString(),
				isSyncing: false
			};
		}),
		updateTimestamp: ( context: SyncContext ) => {
			setStorageItem( 'synctimestamp', context.lastUpdated );
		}
	}
});

export const service = interpret( syncMachine ).start();
