import type { RxCollection, RxDatabase } from 'rxdb';

import { createRxDatabase, addRxPlugin } from 'rxdb';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';

import { collectionSiteSettings } from './schemas/collectionSiteSettings';
import { localcollections } from './schemas/localcollections';
import { applicationSettings } from './schemas/applicationSettings';
import { userData } from './schemas/userData';
import { tempCredentials } from './schemas/credentials';
import { remotecollections } from './schemas/remotecollections';

import { getSavingParams } from '@/constants/routes';
import { saveErrors } from '@/constants/saveErrors';
import { checkHeartbeat } from '@/services/connectionStatus';
import { getCurrentTimestamp, getDateTimestamp } from '@/services/date';
import { getCollections } from '@/services/webservices';
import { service as syncService } from '@/stores/xstate/sync/syncMachine';

addRxPlugin( RxDBMigrationPlugin );
addRxPlugin( RxDBEncryptionPlugin );
addRxPlugin( RxDBLeaderElectionPlugin );

let rxDb: RxDatabase;

async function createDatabase() {
	rxDb = await createRxDatabase({
		name: 'occf_collections',
		storage: getRxStorageDexie(),
		multiInstance: true,
		password: process.env.NEXT_PUBLIC_RXDB_KEY
	});
}

export async function initialize() {
	if ( !rxDb ) {
		await createDatabase();
	}

	if ( !rxDb.applicationsettings ) {
		await rxDb.addCollections({
			collectionsitesettings: {
				schema: collectionSiteSettings
			},
			applicationsettings: {
				schema: applicationSettings
			},
			userdata: {
				schema: userData
			},
			localcollections: {
				schema: localcollections,
				migrationStrategies: {
					4: function( oldDoc ) {
						oldDoc.isSynced = false;
						oldDoc.syncedAt = undefined;

						return oldDoc;
					},
					5: function( oldDoc ) {
						oldDoc.createdAt = new Date();

						return oldDoc;
					},
					6: function( oldDoc ) {
						oldDoc.isStaged = false;

						return oldDoc;
					},
					7: function( oldDoc ) {
						oldDoc.createdBy = '';

						return oldDoc;
					}
				}
			},
			remotecollections: {
				schema: remotecollections
			},
			tmpstorecreds: {
				schema: tempCredentials
			}
		});
	}

	return rxDb;
}

export async function fetchCollections( remoteCollectionDb: RxCollection, localCollectionDb: RxCollection ) {
	let remotecollections;
	let localcollections;

	if ( remoteCollectionDb || !localCollectionDb ) {
		remotecollections = remoteCollectionDb;
		localcollections = localCollectionDb;
	} else {
		if ( !rxDb ) {
			await initialize();

			const db = await initialize();

			remotecollections = db.remotecollections;
			localcollections = db.localcollections;
		}
	}

	// set all staged items as synced
	const stagedCollections = await localcollections?.find({
		selector: { isStaged: { $eq: true } }
	}).exec();

	if ( stagedCollections ) {
		await Promise.all( stagedCollections.map( async ( localDocument: any ) => {
			await localDocument.atomicPatch({
				isSynced: true,
				isStaged: false
			});
		}));
	}

	try {
		await checkHeartbeat();
	
		const remoteCollections = await getCollections({
			startDate: null,
			endDate: null,
			page: 1,
			pageSize: 999,
			searchTerm: '',
			showCompleted: true,
			isIncludeCollectionConsent: true
		});

		const mappedCollections = remoteCollections.collections.map( ( collection: any ) => {
			let collectionObject = collection;
			
			const matchedConsent = remoteCollections.collectionConsents.find( ( consent: any ) => consent.collectionId === collection.id );

			if ( matchedConsent ) {
				collectionObject = {
					...collectionObject,
					collectionConsent: matchedConsent
				};
			}
	
			return {
				collectionId: collection.id,
				collectionObject,
				collectionSiteId: collection.collectionSiteId,
				isSynced: true,
				collectionDate: getDateTimestamp( collection.collectionDate ),
				updateDate: getCurrentTimestamp(),
				status: collection.status
			};
		});
	
		await remotecollections?.bulkUpsert( mappedCollections );
	} catch ( e: any ) {
		throw new Error( e );
	}
}

export async function clearSyncedCollections( remotecollections: any ) {
	const itemsToDelete = await remotecollections.find({}).exec();

	if ( itemsToDelete.length === 0 ) {
		return;
	}

	const idArray = itemsToDelete.map( ( collection: any ) => {
		const parsedDocument = collection.toJSON();

		return parsedDocument.collectionId;
	});

	await remotecollections.bulkRemove( idArray );
}

async function pushToServer( document: any, rxDbDocument: any ) {
	try {
		const updateObj = {
			...document.collectionObject,
			id: document.collectionId.replace( 'temp_', '' )
		};

		let updateParams = getSavingParams( updateObj.meta.lastStepCompleted );

		if ( updateObj.status !== 1 ) {
			updateParams = {
				...updateParams,
				finishCollection: true,
				userPassword: updateObj.meta.collectorPwd
			};
		}

		if ( updateObj.isDonorWithdrawal ) {
			updateParams = {
				...updateParams,
				isDonorWithdrawClicked: true
			};
		}

		// delete properties that are internal state specific
		delete updateObj.meta;

		let updateData = {
			collection: updateObj,
			...updateParams
		};

		const saveRequest = await fetch( '/api/saveCollection', {
			method: 'post',
			body: JSON.stringify( updateData ),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		const data = await saveRequest.json();

		// If the sync wasn't successful, update the local record status
		if ( !saveRequest.ok ) {
			throw new Error( ( data && data.message ) || saveRequest.status );
		} else {
			if ( !data.successful ) {
				const errorMessage = saveErrors[ data.saveResult ]?.message || 'There was an unknown error syncing this record. Please try again.';

				throw new Error( `Syncing collection ${ document.collectionId } failed with the following message: ${ errorMessage }` );
			}
		}

		// if collection has consent object, save the consent object:
		if ( updateData.collection.collectionConsent ) {
			const saveConsentRequest = await fetch( '/api/saveCollectionConsent', {
				method: 'post',
				body: JSON.stringify({
					...updateData,
					collectionConsent: updateData.collection.collectionConsent
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});
	
			const consentData = await saveConsentRequest.json();
	
			if ( !saveConsentRequest.ok ) {
				throw new Error( ( consentData && consentData.message ) || saveConsentRequest.status );
			} else {
				if ( !consentData.successful ) {
					const consentError = saveErrors[ consentData.result ]?.message || 'There was an unknown error saving the collection consent.';
	
					throw new Error( `Syncing collection ${ document.collectionId } failed with the following message: ${ consentError }` );
				}
			}
			// we don't need to update the local record, I don't think, because this only applies to
			// completed records, at which point we'll be getting it back from the server.
		}

		// We've made it through all of the checks, so the update was successful
		// Update the local record's isSynced value
		await rxDbDocument.atomicPatch({
			isStaged: true,
			syncedAt: getCurrentTimestamp()
		});
	} catch ( error: any ) {
		syncService.send({ type: 'ERROR', message: error });
	}
}

async function pushRecordsToServer( rxDb: RxDatabase ) {
	const localDocuments = await rxDb.localcollections?.find( {} ).exec();

	await Promise.all( localDocuments.map( async ( localDocument: any ) => {
		const parsedDocument = localDocument.toJSON();

		if ( parsedDocument.isSynced ) {
			return Promise.resolve();
		}

		await pushToServer( parsedDocument, localDocument );
	}));
}

// For diagnosing sync issues:
async function diagnosePayload( rxDb: RxDatabase ) {
	const localDocuments = await rxDb.localcollections?.find( {} ).exec();

	await Promise.all( localDocuments.map( async ( localDocument: any ) => {
		const parsedDocument = localDocument.toJSON();

		if ( parsedDocument.isSynced ) {
			return Promise.resolve();
		}

		console.log( parsedDocument );

		return Promise.resolve();
	}));
}

export async function syncRecords() {
	// make sure the DB exists
	if ( !rxDb ) {
		await initialize();
	}

	// diagnosePayload( rxDb );

	await pushRecordsToServer( rxDb );

	await fetchCollections( rxDb.remotecollections, rxDb.localcollections );
}
